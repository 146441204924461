import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

/** Activation Status */
export enum ActivationStatus {
  Active = 'Active',
  Deactivated = 'Deactivated'
}

export type Alert = {
  alert_type?: Maybe<AlertType>;
  cause?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description_long?: Maybe<Scalars['String']>;
  description_short?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<PalcodeDocument>>;
  id?: Maybe<Scalars['ID']>;
  is_private?: Maybe<Scalars['Boolean']>;
  is_solved?: Maybe<Scalars['Boolean']>;
  raw_code?: Maybe<Scalars['String']>;
  solution?: Maybe<Scalars['String']>;
  solved_at?: Maybe<Scalars['DateTime']>;
};

export type AlertOverview = {
  alert_count: Scalars['Int'];
};

export type AlertPageResult = {
  page_number: Scalars['Int'];
  records_per_page: Scalars['Int'];
  results: Array<Alert>;
  total_records: Scalars['Int'];
};

export type AlertStatistics = {
  addition?: Maybe<Scalars['String']>;
  alert_type?: Maybe<AlertType>;
  cause?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<PalcodeDocuments>>;
  duration?: Maybe<Scalars['BigInt']>;
  id?: Maybe<Scalars['ID']>;
  is_solved?: Maybe<Scalars['Boolean']>;
  last_occurences_meta_data?: Maybe<Array<LastOccurencesMetaData>>;
  long_description?: Maybe<Scalars['String']>;
  operating_hours?: Maybe<Scalars['BigInt']>;
  short_description?: Maybe<Scalars['String']>;
  solution?: Maybe<Scalars['String']>;
  solved_at?: Maybe<Scalars['DateTime']>;
  status_code?: Maybe<Scalars['String']>;
};

export type AlertStatisticsPageResult = {
  page_number: Scalars['Int'];
  records_per_page: Scalars['Int'];
  results: Array<AlertStatistics>;
  total_records: Scalars['Int'];
};

export enum AlertType {
  Error = 'Error',
  Information = 'Information',
  Warning = 'Warning'
}

export type ApplicationAccess = {
  /**
   *    Access state for claim management
   *
   * Auth: Requires 'palfinger.claimmanagement' scope
   */
  claim_management_access_state?: Maybe<ClaimManagementAccessState>;
  /**
   *    Access state handover fee
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  handover_fee_access_state?: Maybe<CrossBoarderSalesAccessState>;
  /**
   *    Flag indicating if user can send an invite to end-customer for PALDESK
   *
   * Auth: No scope required
   */
  has_invite_to_paldesk_access: Scalars['Boolean'];
  /**
   *    Flag indicating if user can access the oil monitoring
   *
   * Auth: Requires 'palfinger.oilmonitoring' scope
   */
  has_oil_check_access: Scalars['Boolean'];
  /**
   *    Flag indicating if user can generate a palchart
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  has_palchart_access: Scalars['Boolean'];
  /**
   *    Flag indicating if user can create a service ticket
   *
   * Auth: No scope required
   */
  has_service_tickets_access: Scalars['Boolean'];
  /**
   *    Flag indicating if user has access to sso
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  has_sso_access: Scalars['Boolean'];
  /**
   *    Flag indicating if user can access the taillift shop
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  has_taillift_shop_access: Scalars['Boolean'];
  /**
   *    Access state for pre delivery inspection
   *
   * Auth: Requires 'ms.service-inspection' scope
   */
  pre_delivery_inspection_access_state: PreDeliveryInspectionAccessState;
  /**
   *    Access info for product registration
   *
   * Auth: Requires 'palfinger.productregistrationv2' scope
   */
  product_registration_access_info: ProductRegistrationAccessInfo;
  /**
   *    Access state for service contract configurator
   *
   * Auth: Requires 'palfinger.servicecontract' scope
   */
  service_contract_configurator_access_state: ServiceContractConfiguratorAccessState;
  /**
   *    Access state service fee
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  service_fee_access_state?: Maybe<CrossBoarderSalesAccessState>;
  /**
   *    Access state for smart handover
   *
   * Auth: Requires 'ms.service-inspection' scope
   */
  smart_handover_access_state: SmartHandoverAccessState;
  /**
   *    Access state for smart inspection
   *
   * Auth: Requires 'ms.service-inspection' scope
   */
  smart_inspection_access_state: SmartInspectionAccessState;
  /**
   *    Access state for smart records
   *
   * Auth: No scope required
   */
  smart_record_access_state: SmartRecordAccessState;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Carrier = {
  /** Equipments of the carrier. */
  equipments: Array<Equipment>;
  id?: Maybe<Scalars['String']>;
  /** License plate or license number of the carrier */
  license_plate?: Maybe<Scalars['String']>;
  /** Truck manufacturer */
  manufacturer?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  /** Truck type */
  truck_type?: Maybe<Scalars['String']>;
  /** Truck model */
  type?: Maybe<Scalars['String']>;
  /** Identification number of the carrier */
  vin?: Maybe<Scalars['String']>;
};


export type CarrierEquipmentsArgs = {
  is_configurable?: InputMaybe<Scalars['Boolean']>;
};

/** Carrier manufacturer */
export enum CarrierManufacturer {
  AmGeneral = 'AmGeneral',
  Amw = 'Amw',
  AshokLeyland = 'AshokLeyland',
  Astra = 'Astra',
  Autocar = 'Autocar',
  Bmc = 'Bmc',
  Caterpillar = 'Caterpillar',
  Chevrolet = 'Chevrolet',
  Citroen = 'Citroen',
  CraneCarrier = 'CraneCarrier',
  Daewoo = 'Daewoo',
  Daf = 'Daf',
  Daihaitsu = 'Daihaitsu',
  Dina = 'Dina',
  Dodge = 'Dodge',
  Eicher = 'Eicher',
  Erf = 'Erf',
  Farga = 'Farga',
  Fiat = 'Fiat',
  Foden = 'Foden',
  Ford = 'Ford',
  Foton = 'Foton',
  Freightliner = 'Freightliner',
  Fuso = 'Fuso',
  Gaz = 'Gaz',
  Ginaf = 'Ginaf',
  Gmc = 'Gmc',
  GrummanOlson = 'GrummanOlson',
  Hino = 'Hino',
  Hyundai = 'Hyundai',
  International = 'International',
  Isuzu = 'Isuzu',
  Iveco = 'Iveco',
  Jenz = 'Jenz',
  Jienfang = 'Jienfang',
  Kamaz = 'Kamaz',
  Kenworth = 'Kenworth',
  Liaz = 'Liaz',
  Mack = 'Mack',
  Magirus = 'Magirus',
  Mahindra = 'Mahindra',
  Man = 'Man',
  Maz = 'Maz',
  Mazda = 'Mazda',
  MercedesBenz = 'MercedesBenz',
  Mitsubishi = 'Mitsubishi',
  Navistar = 'Navistar',
  Nissan = 'Nissan',
  Opel = 'Opel',
  Oshkosh = 'Oshkosh',
  Pegaso = 'Pegaso',
  Peterbilt = 'Peterbilt',
  Peugeot = 'Peugeot',
  Raba = 'Raba',
  Ram = 'Ram',
  Renault = 'Renault',
  Scania = 'Scania',
  Sisu = 'Sisu',
  Sterling = 'Sterling',
  Steyr = 'Steyr',
  Tatra = 'Tatra',
  Terberg = 'Terberg',
  ToyotaHino = 'ToyotaHino',
  Ud = 'Ud',
  Unimog = 'Unimog',
  Ural = 'Ural',
  Utimec = 'Utimec',
  Volkswagen = 'Volkswagen',
  Volvo = 'Volvo',
  WesternStar = 'WesternStar',
  Westmaster = 'Westmaster',
  WhiteGmc = 'WhiteGmc',
  Workhorse = 'Workhorse',
  Zil = 'Zil'
}

export type ChangeEndCustomerInput = {
  end_customer_number: Scalars['String'];
  equipment_number: Scalars['String'];
};

export type ChangeEndCustomerResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Claim management access state */
export enum ClaimManagementAccessState {
  AdditionalEquipment = 'AdditionalEquipment',
  NotAuthorized = 'NotAuthorized',
  SalesOrganizationMissing = 'SalesOrganizationMissing',
  Success = 'Success'
}

export type Configuration = {
  description?: Maybe<Scalars['String']>;
  editableType?: Maybe<IsAConfigurationParameterEditable>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueDescription?: Maybe<Scalars['String']>;
};

export type ConfigurationDelta = {
  description?: Maybe<Scalars['String']>;
  editableType?: Maybe<IsAConfigurationParameterEditable>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  newValue?: Maybe<Scalars['String']>;
  newValueDescription?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  oldValueDescription?: Maybe<Scalars['String']>;
};

/** Cross boarder sales access state */
export enum CrossBoarderSalesAccessState {
  DealerFromSameCountry = 'DealerFromSameCountry',
  DealerNotSet = 'DealerNotSet',
  FeeRequestAlreadyApplied = 'FeeRequestAlreadyApplied',
  FeeRequestNotInTimeframe = 'FeeRequestNotInTimeframe',
  MissingDeliveryDate = 'MissingDeliveryDate',
  ProductRegistrationNotAvailable = 'ProductRegistrationNotAvailable',
  ProductTypeNotSupported = 'ProductTypeNotSupported',
  RolesNotConfigured = 'RolesNotConfigured',
  SalesAndServiceEntityNotSupported = 'SalesAndServiceEntityNotSupported',
  Success = 'Success',
  TenderEquipment = 'TenderEquipment'
}

export type Customer = {
  active_alert_count_assigned: Scalars['Int'];
  /**
   *    Alert count of all equipments assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  active_alert_count_total: Scalars['Int'];
  active_sim_count_assigned: Scalars['Int'];
  assigned_service_partner_count: Scalars['Int'];
  assigned_service_partner_name?: Maybe<Scalars['String']>;
  assigned_service_partner_number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** Concatenated name and name2 */
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  recall_action_count_assigned: Scalars['Int'];
  /**
   *    Recall actions of all equipments assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  recall_action_count_total: Scalars['Int'];
  service_contract_count_assigned: Scalars['Int'];
  service_status_overview_assigned?: Maybe<ServiceStatusOverview>;
  /**
   *    Service status counts of all equipments assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  service_status_overview_total?: Maybe<ServiceStatusOverview>;
  street?: Maybe<Scalars['String']>;
  telematic_overview_assigned?: Maybe<TelematicOverview>;
  /**
   *    Telematic counts of all equipments assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  telematic_overview_total?: Maybe<TelematicOverview>;
  type: PartnerType;
};

/** Order by for customers */
export enum CustomerOrderBy {
  ActiveSimCount = 'ActiveSimCount',
  AlertCount = 'AlertCount',
  EquipmentCount = 'EquipmentCount',
  Name = 'Name',
  RecallActionCount = 'RecallActionCount',
  RetrofittableCount = 'RetrofittableCount',
  ServiceContractCount = 'ServiceContractCount',
  ServiceOverDueCount = 'ServiceOverDueCount',
  TelematicCount = 'TelematicCount'
}

export type CustomerPageResult = {
  page_number: Scalars['Int'];
  records_per_page: Scalars['Int'];
  results: Array<Customer>;
  total_records: Scalars['Int'];
};

export type CustomerSearchCriteria = {
  has_active_alerts?: InputMaybe<Scalars['Boolean']>;
  /** Filter for partners that have users in BPM */
  has_bpm_users?: InputMaybe<Scalars['Boolean']>;
  has_retrofittable?: InputMaybe<Scalars['Boolean']>;
  numbers?: InputMaybe<Array<Scalars['String']>>;
  /** Filter for partners with assigned service partners */
  service_partner_numbers?: InputMaybe<Array<Scalars['String']>>;
  service_required?: InputMaybe<Scalars['Boolean']>;
  /** Filter for partners with assinged equipment having the specified sim status */
  sim_status?: InputMaybe<Array<SimStatus>>;
};

export type CustomerUpdate = {
  city: Scalars['String'];
  comment: Scalars['String'];
  country: Scalars['String'];
  country_code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  equipment_number: Scalars['String'];
  house_number: Scalars['String'];
  id: Scalars['String'];
  mobile?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type DeltaBom = {
  new_parts: Array<Part>;
  removed_parts: Array<Part>;
};

export type DeltaBomInput = {
  simulated_configuration: Array<InterpreterAttribute>;
};

export type Details = {
  details_summary_status?: Maybe<Scalars['String']>;
  details_type?: Maybe<Scalars['String']>;
};

export type Document = {
  document_class_code: Scalars['String'];
  document_class_name: Scalars['String'];
  epc_book_config?: Maybe<EpcBookConfig>;
  id: Scalars['String'];
  supported_languages: Array<Scalars['String']>;
  title: Scalars['String'];
  upload_date?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['String']>;
  version: Scalars['String'];
  view_urls: Array<DocumentUrl>;
};

export type DocumentClass = {
  identifier: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type DocumentMetaData = {
  document_class: Scalars['String'];
  equipment_codes: Array<Scalars['String']>;
  language: Scalars['String'];
  preview_image: Scalars['Boolean'];
  search_term: Scalars['String'];
  title: Scalars['String'];
};

export type DocumentUrl = {
  language: Scalars['String'];
  url: Scalars['String'];
};

export type EditConfigurationParameters = {
  configuration_id: Scalars['String'];
  new_value?: InputMaybe<Scalars['String']>;
};

export type EditableConfiguration = {
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<EditableConfigurationValue>>;
};

export type EditableConfigurationValue = {
  value?: Maybe<Scalars['String']>;
  valueDescription?: Maybe<Scalars['String']>;
};

export type EpcBookConfig = {
  book_id: Scalars['Int'];
  fittings: Scalars['Int'];
  ktype?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type EpcBookConfigInput = {
  add_associated_books: Scalars['Boolean'];
  book_id: Scalars['Int'];
  equipment_number?: InputMaybe<Scalars['String']>;
  fittings: Scalars['Int'];
  ktype?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  style: EpcPdfStyle;
  units?: InputMaybe<Scalars['Int']>;
};

export type EpcPdf = {
  url: Scalars['String'];
};

/** Styles for EpcPdfBuilder */
export enum EpcPdfStyle {
  Download = 'Download',
  File = 'File',
  Print = 'Print'
}

export type Equipment = {
  /** @deprecated Inacurrate value, use connected_active_alert_count instead */
  active_alert_count?: Maybe<Scalars['Int']>;
  /**
   *    Active alerts for this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  active_alerts: Array<Alert>;
  active_service_contracts_count: Scalars['Int'];
  /**
   *    Alert history for this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  alert_history: AlertPageResult;
  alert_statistic: AlertStatisticsPageResult;
  /** Application access for this equipment */
  application_access: ApplicationAccess;
  /**
   *    Build month and year seperated by a slash for this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  build_date?: Maybe<Scalars['String']>;
  /**
   *    Carrier this equipment is mounted on
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  carrier: Carrier;
  carrier_name?: Maybe<Scalars['String']>;
  /**
   *    Variant configuration delta for this equipment
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  configuration_delta: Array<ConfigurationDelta>;
  /**
   *    Variant configuration for this equipment
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  configurations: Array<Configuration>;
  connected_active_alert_count: Scalars['Int'];
  /**
   *    Days to service for this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  days_to_service?: Maybe<Scalars['Int']>;
  dealer_warranty_end?: Maybe<Scalars['DateTime']>;
  dealer_warranty_number?: Maybe<Scalars['String']>;
  dealer_warranty_start?: Maybe<Scalars['DateTime']>;
  delivery_date?: Maybe<Scalars['DateTime']>;
  /**
   *    Delta BOM for this equipment compared to the provided configuration
   *
   * Auth: Requires 'ms.catalog' scope
   */
  delta_bom: DeltaBom;
  expected_service_date?: Maybe<Scalars['DateTime']>;
  /**
   *    Variant factory configuration for this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  factory_configuration: Array<Configuration>;
  /**
   *    Variant field configuration for this equipment
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  field_configuration: Array<Configuration>;
  /**
   *    Handover and Service Fees.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  handover_and_service_fees?: Maybe<HandoverAndServiceFee>;
  has_active_alerts: Scalars['Boolean'];
  /** Check if there are inspections */
  has_inspections?: Maybe<Scalars['Boolean']>;
  /** Check if there are oil checks */
  has_oil_checks?: Maybe<Scalars['Boolean']>;
  has_telematics: Scalars['Boolean'];
  id: Scalars['ID'];
  is_in_warranty: Scalars['Boolean'];
  is_main_equipment: Scalars['Boolean'];
  /**
   *    Is this equipment registered
   *
   * Auth: Requires 'palfinger.productregistrationv2' scope
   */
  is_product_registered: Scalars['Boolean'];
  is_retrofittable: Scalars['Boolean'];
  is_scrapped: Scalars['Boolean'];
  /** Get last inspection based on date */
  last_inspection?: Maybe<ServiceRecord>;
  last_inspection_date?: Maybe<Scalars['DateTime']>;
  last_operating_hours_update_date?: Maybe<Scalars['DateTime']>;
  /** Get the latest oil check based on date */
  latest_oil_check?: Maybe<ServiceRecord>;
  /**
   *    Latest service contract for this equipment
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  latest_service_contract?: Maybe<ServiceContract>;
  manufacturer_serial_number?: Maybe<Scalars['String']>;
  /** @deprecated Inacurrate value, use palfinger_warranty_number instead */
  master_warranty_number?: Maybe<Scalars['String']>;
  material_number?: Maybe<Scalars['String']>;
  mounting_type: MountingType;
  name?: Maybe<Scalars['String']>;
  next_inspection_date?: Maybe<Scalars['DateTime']>;
  number: Scalars['String'];
  /**
   *    Oil Information for equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  oil_information?: Maybe<OilInformation>;
  operating_hours?: Maybe<Scalars['Int']>;
  order_number?: Maybe<Scalars['String']>;
  /** Auth: No scope required */
  palfinger_documents: Array<Document>;
  palfinger_documents_count: Scalars['Int'];
  palfinger_warranty_end?: Maybe<Scalars['DateTime']>;
  palfinger_warranty_number?: Maybe<Scalars['String']>;
  palfinger_warranty_start?: Maybe<Scalars['DateTime']>;
  /** Auth: No scope required */
  partner_documents: Array<Document>;
  partner_documents_count: Scalars['Int'];
  /**
   *    Partners assigned to this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  partners: Array<Partner>;
  /**
   *    Pictures for this equipment
   *
   * Auth: No scope required
   */
  pictures: Array<EquipmentPicture>;
  /**
   *    Product for this equipment
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  product?: Maybe<EquipmentProduct>;
  recall_action_count: Scalars['Int'];
  /**
   *    Recall actions for this equipment from ED
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  recall_actions_new: Array<RecallAction>;
  serial_number?: Maybe<Scalars['String']>;
  /**
   *    Service contracts for this equipment
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  service_contracts: Array<ServiceContract>;
  /**
   *    Service Records
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  service_records: Array<ServiceRecord>;
  service_status?: Maybe<ServiceStatus>;
  sim_status: SimStatus;
  /**
   *    Telematic activation history for this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  telematic_activation_history?: Maybe<Array<TelematicActivationHistory>>;
  /**
   *    Telematic info for this equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  telematic_info?: Maybe<TelematicInfo>;
  /**
   *    Tuning Center information for this equipment
   *
   * Auth: Requires 'palfinger.microservice.sap' scope
   */
  tuning_center?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /** @deprecated Inacurrate value, use palfinger_warranty_end instead */
  warranty_end?: Maybe<Scalars['DateTime']>;
  /** @deprecated Inacurrate value, use palfinger_warranty_start instead */
  warranty_start?: Maybe<Scalars['DateTime']>;
};


export type EquipmentAlert_HistoryArgs = {
  code?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortDirection>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Array<AlertType>>;
};


export type EquipmentAlert_StatisticArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortDirection>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type EquipmentDelta_BomArgs = {
  data: DeltaBomInput;
};


export type EquipmentPartnersArgs = {
  types?: InputMaybe<Array<PartnerType>>;
};

/** Fields to use to distinct equipments */
export enum EquipmentDistinct {
  Name = 'Name',
  Type = 'Type'
}

/** Order by */
export enum EquipmentOrderBy {
  EquipmentAlertCount = 'EquipmentAlertCount',
  EquipmentCarrier = 'EquipmentCarrier',
  EquipmentCustomer = 'EquipmentCustomer',
  EquipmentIsRetrofittable = 'EquipmentIsRetrofittable',
  EquipmentName = 'EquipmentName',
  EquipmentNumber = 'EquipmentNumber',
  EquipmentRecallActionCount = 'EquipmentRecallActionCount',
  EquipmentRegistration = 'EquipmentRegistration',
  EquipmentSerialNumber = 'EquipmentSerialNumber',
  EquipmentServiceContractCount = 'EquipmentServiceContractCount',
  EquipmentServiceDate = 'EquipmentServiceDate',
  EquipmentServicePartner = 'EquipmentServicePartner',
  EquipmentSimStatus = 'EquipmentSimStatus',
  EquipmentWarrantyEnd = 'EquipmentWarrantyEnd'
}

export type EquipmentPageResult = {
  page_number: Scalars['Int'];
  records_per_page: Scalars['Int'];
  results: Array<ListEquipment>;
  total_records: Scalars['Int'];
};

export type EquipmentPicture = {
  name: Scalars['String'];
  palipedia_id: Scalars['Float'];
  picture_url: Scalars['String'];
  thumbnail_url: Scalars['String'];
  youtube_url?: Maybe<Scalars['String']>;
};

export type EquipmentProduct = {
  identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EquipmentSearchCriteria = {
  customer_ids?: InputMaybe<Array<Scalars['String']>>;
  equipment_details?: InputMaybe<Scalars['String']>;
  equipment_names?: InputMaybe<Array<Scalars['String']>>;
  equipment_type_ids?: InputMaybe<Array<Scalars['String']>>;
  from_next_inspection_date?: InputMaybe<Scalars['DateTime']>;
  has_active_alerts?: InputMaybe<Scalars['Boolean']>;
  has_recall_actions?: InputMaybe<Scalars['Boolean']>;
  has_service_contracts?: InputMaybe<Scalars['Boolean']>;
  has_telematics?: InputMaybe<Scalars['Boolean']>;
  is_in_warranty?: InputMaybe<Scalars['Boolean']>;
  is_main_equipment?: InputMaybe<Scalars['Boolean']>;
  is_registered?: InputMaybe<Scalars['Boolean']>;
  is_retrofittable?: InputMaybe<Scalars['Boolean']>;
  recall_action_external_id?: InputMaybe<Array<Scalars['String']>>;
  service_partners?: InputMaybe<Array<Scalars['String']>>;
  service_status?: InputMaybe<Array<ServiceStatus>>;
  sim_status?: InputMaybe<Array<SimStatus>>;
  to_next_inspection_date?: InputMaybe<Scalars['DateTime']>;
};

export type EquipmentTypeCount = {
  count: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
};

export type ExportConfigurationInfoByNumber = {
  equipment_number: Scalars['String'];
  format: Scalars['String'];
};

export type FeatureFlag = {
  is_enabled: Scalars['Boolean'];
};

export type File = {
  file_url?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type GeneratePalchartDataInput = {
  file_url: Scalars['String'];
  language: Scalars['String'];
  unit: Scalars['String'];
};

export type GenerateSsoDataInput = {
  file_name: Scalars['String'];
};

export type HandoverAndServiceFee = {
  handover_fee?: Maybe<Scalars['String']>;
  service_fee?: Maybe<Scalars['String']>;
  service_fee_currency?: Maybe<Scalars['String']>;
};

export type HealthStatus = {
  results: Scalars['JSONObject'];
  status: Scalars['String'];
};

export type InterpreterAttribute = {
  current_values?: InputMaybe<Array<InterpreterAttributeValue>>;
  name?: InputMaybe<Scalars['String']>;
};

export type InterpreterAttributeValue = {
  value?: InputMaybe<Scalars['String']>;
};

export enum IsAConfigurationParameterEditable {
  NotEditable = 'NotEditable',
  OpenTextEditable = 'OpenTextEditable',
  SsoEditable = 'SsoEditable',
  ValueListEditable = 'ValueListEditable'
}

export type LastOccurencesMetaData = {
  date?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Float']>;
};

export type ListEquipment = {
  /** @deprecated Inacurrate value, use connected_active_alert_count instead */
  active_alert_count?: Maybe<Scalars['Int']>;
  carrier_name?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_number?: Maybe<Scalars['String']>;
  expected_service_date?: Maybe<Scalars['DateTime']>;
  has_active_alerts: Scalars['Boolean'];
  has_telematics: Scalars['Boolean'];
  is_in_warranty: Scalars['Boolean'];
  is_product_registered: Scalars['Boolean'];
  is_retrofittable: Scalars['Boolean'];
  manufacturer_serial_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  next_legal_inspection_date?: Maybe<Scalars['DateTime']>;
  number: Scalars['ID'];
  recall_action_count: Scalars['Int'];
  recall_action_external_id?: Maybe<Array<Scalars['String']>>;
  serial_number?: Maybe<Scalars['String']>;
  service_contract_count: Scalars['Int'];
  service_partner_name?: Maybe<Scalars['String']>;
  service_partner_number?: Maybe<Scalars['String']>;
  service_status?: Maybe<ServiceStatus>;
  sim_status: SimStatus;
  type?: Maybe<Scalars['String']>;
  warranty_end?: Maybe<Scalars['DateTime']>;
  warranty_start?: Maybe<Scalars['DateTime']>;
};

/** Installation location or mounting type of the equipment */
export enum MountingType {
  BuildingMaintenance = 'BuildingMaintenance',
  Center = 'Center',
  CrawlerDetachable = 'CrawlerDetachable',
  CrawlerFix = 'CrawlerFix',
  Detachable = 'Detachable',
  FactoryIndoor = 'FactoryIndoor',
  FactoryYard = 'FactoryYard',
  Front = 'Front',
  None = 'None',
  OffshoreStationary = 'OffshoreStationary',
  OnshoreStationary = 'OnshoreStationary',
  Rear = 'Rear',
  Roof = 'Roof',
  ShiftingSled = 'ShiftingSled',
  ShipboardMounted = 'ShipboardMounted',
  Special = 'Special',
  StationaryMounted = 'StationaryMounted',
  Substation = 'Substation',
  Trailer = 'Trailer',
  VesselCenter = 'VesselCenter',
  VesselFront = 'VesselFront',
  VesselPortSide = 'VesselPortSide',
  VesselRear = 'VesselRear',
  VesselStarboard = 'VesselStarboard',
  WindNacelle = 'WindNacelle',
  WindPlatform = 'WindPlatform'
}

export type Mutation = {
  changeEndCustomer: ChangeEndCustomerResult;
  setCarrierManufacturerAndType: SetCarrierManufacturerAndTypeResult;
  setCarrierVin: SetVinDataResult;
  setConfigurationValue: SetConfigurationValueResult;
  setDeleteActiveInspection: SetDeleteActiveInspectionResult;
  setEditOilInformationData: SetEditOilInformationDataResult;
  setEndCustomerData: SetEndCustomerDataResult;
  setGeneratePalchart: SetGeneratePalchartResult;
  setGenerateSSO: SetGenerateSsoResult;
  setLicensePlateData: SetLicensePlateDataResult;
  setNextLegalInspectionDate: SetEquipmentLegalInspectionDateResult;
  setPartnerDocument: SetPartnerDocumentResult;
  setRemoveEndCustomer: SetRemoveEndCustomerDataResult;
  setScrapEquipment: SetScrapEquipmentDataResult;
  setServicePartner: SetServicePartnerResult;
  setTimeToServiceDate: SetTimeToServiceDataResult;
  setWarrantyStartDate: SetWarrantyDataResult;
};


export type MutationChangeEndCustomerArgs = {
  data: ChangeEndCustomerInput;
};


export type MutationSetCarrierManufacturerAndTypeArgs = {
  data: SetCarrierManufacturerAndTypeInput;
};


export type MutationSetCarrierVinArgs = {
  data: SetVinDataInput;
};


export type MutationSetConfigurationValueArgs = {
  data: SetConfigurationValueInput;
};


export type MutationSetDeleteActiveInspectionArgs = {
  data: SetDeleteActiveInspectionInput;
};


export type MutationSetEditOilInformationDataArgs = {
  data: SetEditOilInformationInput;
};


export type MutationSetEndCustomerDataArgs = {
  data: SetEndCustomerDataInput;
};


export type MutationSetGeneratePalchartArgs = {
  data: GeneratePalchartDataInput;
};


export type MutationSetGenerateSsoArgs = {
  data: GenerateSsoDataInput;
};


export type MutationSetLicensePlateDataArgs = {
  data: SetLicensePlateDataInput;
};


export type MutationSetNextLegalInspectionDateArgs = {
  data: SetEquipmentLegalInspectionDateInput;
};


export type MutationSetPartnerDocumentArgs = {
  data: SetPartnerDocumentInput;
};


export type MutationSetRemoveEndCustomerArgs = {
  data: SetRemoveEndCustomerDataInput;
};


export type MutationSetScrapEquipmentArgs = {
  data: SetScrapEquipmentInput;
};


export type MutationSetServicePartnerArgs = {
  data: SetServicePartnerInput;
};


export type MutationSetTimeToServiceDateArgs = {
  data: SetTimeToServiceDateInput;
};


export type MutationSetWarrantyStartDateArgs = {
  data: SetWarrantyDateInput;
};

export type OilInformation = {
  custom_oil_type?: Maybe<Scalars['String']>;
  oil_type?: Maybe<OilType>;
  registered_at?: Maybe<Scalars['String']>;
  viscosity?: Maybe<Scalars['String']>;
};

export type OilInformationUpdate = {
  custom_oil_type?: InputMaybe<Scalars['String']>;
  oil_type: Scalars['String'];
  operating_hours?: InputMaybe<Scalars['Int']>;
  viscosity?: InputMaybe<Scalars['String']>;
};

/** Type of Oil */
export enum OilType {
  OtherBiodegradable = 'OtherBiodegradable',
  OtherMineral = 'OtherMineral',
  PalfingerHydraulicBio = 'PalfingerHydraulicBio',
  PalfingerHydraulicExtreme = 'PalfingerHydraulicExtreme',
  PalfingerHydraulicPremium = 'PalfingerHydraulicPremium'
}

export type PalcodeDocument = {
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PalcodeDocuments = {
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Part = {
  description: Scalars['String'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type Partner = {
  /**  Alert count of all equipments assigned to this partner */
  alert_overview_total?: Maybe<AlertOverview>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  /**
   *    Paginated list of customers with additional information about their assigned equipment.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  customers: CustomerPageResult;
  /**
   *    Temporary field resolver for customers. Used for transformation to customers order by enum
   *
   * Auth: Requires 'ms.equipmentcore' scope
   * @deprecated Use customers instead. This field was only used for transformation to customers order by enum
   */
  customers_new: CustomerPageResult;
  email?: Maybe<Scalars['String']>;
  /**
   *    Paginated list of equipment assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  equipments: EquipmentPageResult;
  /** Concatenated name and name2 */
  full_name?: Maybe<Scalars['String']>;
  house_number?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_in_bpm?: Maybe<Scalars['Boolean']>;
  mobile_phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  /**
   *    Paginated list of basic information about partners assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  partners: PartnerPageResult;
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  /**  Recall action count of all equipments assigned to this partner */
  recall_action_overview_total?: Maybe<RecallActionOverview>;
  /**  Service contract count of all equipments assigned to this partner */
  service_contract_overview_total?: Maybe<ServiceContractOverview>;
  /**
   *    Paginated list of service partners with additional information about their assigned equipment and customers.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  service_partners: ServicePartnerPageResult;
  /**
   *    Temporary field resolver for service partners. Used for transformation to service partners order by enum
   *
   * Auth: Requires 'ms.equipmentcore' scope
   * @deprecated Use service_partners instead. This field was only used for transformation to service partners order by enum
   */
  service_partners_new: ServicePartnerPageResult;
  /**
   *    Service status counts of equipments assigned to this partner and to the requesting partner (equipment intersection)
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  service_status_overview_assigned?: Maybe<ServiceStatusOverview>;
  /**
   *    Service status counts of all equipments assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  service_status_overview_total?: Maybe<ServiceStatusOverview>;
  street?: Maybe<Scalars['String']>;
  /**
   *    Telematic counts of equipments assigned to this partner and to the requesting partner (equipment intersection)
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  telematic_overview_assigned?: Maybe<TelematicOverview>;
  /**
   *    Telematic counts of all equipments assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  telematic_overview_total?: Maybe<TelematicOverview>;
  type: PartnerType;
  url?: Maybe<Scalars['String']>;
};


export type PartnerCustomersArgs = {
  direction?: InputMaybe<SortDirection>;
  order_by?: InputMaybe<PartnerOrderBy>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  search_criteria?: InputMaybe<CustomerSearchCriteria>;
};


export type PartnerCustomers_NewArgs = {
  direction?: InputMaybe<SortDirection>;
  order_by?: InputMaybe<CustomerOrderBy>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  search_criteria?: InputMaybe<CustomerSearchCriteria>;
};


export type PartnerEquipmentsArgs = {
  direction?: InputMaybe<SortDirection>;
  distinct_by?: InputMaybe<EquipmentDistinct>;
  order_by?: InputMaybe<EquipmentOrderBy>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  search_criteria?: InputMaybe<EquipmentSearchCriteria>;
};


export type PartnerPartnersArgs = {
  has_bpm_users?: InputMaybe<Scalars['Boolean']>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  search_term?: InputMaybe<Scalars['String']>;
  type: PartnerType;
};


export type PartnerService_PartnersArgs = {
  direction?: InputMaybe<SortDirection>;
  order_by?: InputMaybe<PartnerOrderBy>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  search_criteria?: InputMaybe<ServicePartnerSearchCriteria>;
};


export type PartnerService_Partners_NewArgs = {
  direction?: InputMaybe<SortDirection>;
  order_by?: InputMaybe<ServicePartnerOrderBy>;
  page_number?: InputMaybe<Scalars['Int']>;
  records_per_page?: InputMaybe<Scalars['Int']>;
  search_criteria?: InputMaybe<ServicePartnerSearchCriteria>;
};

/** Order by for partners */
export enum PartnerOrderBy {
  ActiveSimCount = 'ActiveSimCount',
  AlertCount = 'AlertCount',
  AssignedCustomerCount = 'AssignedCustomerCount',
  AssignedCustomersWithBpmUsersCount = 'AssignedCustomersWithBpmUsersCount',
  AssignedServicePartner = 'AssignedServicePartner',
  EquipmentCount = 'EquipmentCount',
  Name = 'Name',
  RecallActionCount = 'RecallActionCount',
  RetrofittableCount = 'RetrofittableCount',
  ServiceContractCount = 'ServiceContractCount',
  ServiceDueCount = 'ServiceDueCount',
  ServiceOverDueCount = 'ServiceOverDueCount',
  TelematicCount = 'TelematicCount'
}

export type PartnerPageResult = {
  page_number: Scalars['Int'];
  records_per_page: Scalars['Int'];
  results: Array<Partner>;
  total_records: Scalars['Int'];
};

/** Sap Partner role. One company can have multiple partner roles. */
export enum PartnerType {
  Ag = 'AG',
  Area = 'Area',
  Customer = 'Customer',
  Dealer = 'Dealer',
  GeneralAgent = 'GeneralAgent',
  ServicePartner = 'ServicePartner'
}

/** Pre-delivery inspection access state */
export enum PreDeliveryInspectionAccessState {
  AlreadyInitialized = 'AlreadyInitialized',
  ProductTypeNotSupported = 'ProductTypeNotSupported',
  RolesNotConfigured = 'RolesNotConfigured',
  Success = 'Success'
}

export type ProductRegistrationAccessInfo = {
  access_state: ProductRegistrationAccessState;
};

/** Product registration access state */
export enum ProductRegistrationAccessState {
  AlreadyRegistered = 'AlreadyRegistered',
  AssignedToDealer = 'AssignedToDealer',
  AssignedToDifferentDealer = 'AssignedToDifferentDealer',
  CrawlersDisabled = 'CrawlersDisabled',
  NotAMainProduct = 'NotAMainProduct',
  NotAuthorized = 'NotAuthorized',
  ProcessNotAvailableForAutomatedWarranty = 'ProcessNotAvailableForAutomatedWarranty',
  Success = 'Success',
  UploadNotPossible = 'UploadNotPossible'
}

export type Query = {
  /**
   *    Export assigned equipment data as csv file
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  assigned_equipments_export: TextFile;
  /**
   *    Assigned main equipment types and the number of equipments of each type
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  assigned_equipments_type_overview: Array<EquipmentTypeCount>;
  /**
   *    Get Carrier by number.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  carrier_by_number: Carrier;
  /**
   *    Export equipment data of specified customer as csv file
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  customer_equipments_export: TextFile;
  /**
   *    Export customers data as csv file
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  customers_export: TextFile;
  /**
   *    Get editable configurations by id.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  editable_configuration_params: EditableConfiguration;
  /**
   *    Request the download url for the specified epc book pdf
   *
   * Auth: Requires 'ms.catalog' scope
   */
  epc_pdf: EpcPdf;
  /**
   *    Equipment by number.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  equipment: Equipment;
  /**
   *    Export equipment configuration as csv or pdf file by number
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  equipment_configuration_export_by_number: TextFile;
  /**
   *    Checks if an equipment exists by equipment number or serial number.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  equipment_exists: Scalars['Boolean'];
  feature_flag: FeatureFlag;
  health_check: HealthStatus;
  me: Partner;
  /**
   *    Partner by number. Only assigned partners can be retrieved.
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  partner: Partner;
  /**
   *    Get available document classes for partner document upload
   *
   * Auth: No scope required
   */
  partner_document_upload_classes: Array<DocumentClass>;
  /**
   *    Export service partners data as csv file
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  service_partners_export: TextFile;
  /**
   *    Validate a sso option for a specific equipment
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  validate_sso: SsoValidation;
};


export type QueryAssigned_Equipments_ExportArgs = {
  search_criteria?: InputMaybe<EquipmentSearchCriteria>;
};


export type QueryCarrier_By_NumberArgs = {
  carrier_number: Scalars['String'];
};


export type QueryCustomer_Equipments_ExportArgs = {
  customer_number: Scalars['String'];
};


export type QueryCustomers_ExportArgs = {
  search_criteria?: InputMaybe<CustomerSearchCriteria>;
};


export type QueryEditable_Configuration_ParamsArgs = {
  configuration_id: Scalars['String'];
};


export type QueryEpc_PdfArgs = {
  book_config: EpcBookConfigInput;
};


export type QueryEquipmentArgs = {
  equipment_number: Scalars['String'];
};


export type QueryEquipment_Configuration_Export_By_NumberArgs = {
  export_configuration_info_by_number?: InputMaybe<ExportConfigurationInfoByNumber>;
};


export type QueryEquipment_ExistsArgs = {
  equipment_or_serial_number: Scalars['String'];
};


export type QueryFeature_FlagArgs = {
  name: Scalars['String'];
};


export type QueryPartnerArgs = {
  partner_number: Scalars['String'];
  partner_type: PartnerType;
};


export type QueryService_Partners_ExportArgs = {
  search_criteria?: InputMaybe<ServicePartnerSearchCriteria>;
};


export type QueryValidate_SsoArgs = {
  file_name: Scalars['String'];
};

export type RecallAction = {
  creation_date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  files: Array<RecallActionFile>;
  id?: Maybe<Scalars['String']>;
  recall_action_state?: Maybe<RecallState>;
  template_id: Scalars['Int'];
};

export type RecallActionFile = {
  language: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type RecallActionOverview = {
  recall_actions_count: Scalars['Int'];
};

/** Recall action state */
export enum RecallState {
  ClaimCreated = 'ClaimCreated',
  ClosingDateReached = 'ClosingDateReached',
  ClosingDateReset = 'ClosingDateReset',
  ManuallyMarkedAsDone = 'ManuallyMarkedAsDone',
  New = 'New',
  Removed = 'Removed'
}

export type SsoValidation = {
  equipment_number?: Maybe<Scalars['String']>;
  error_message?: Maybe<Scalars['String']>;
  option: Scalars['String'];
  status: SsoValidationStatus;
};

/** Status of SSO validation */
export enum SsoValidationStatus {
  AlreadyConfigured = 'AlreadyConfigured',
  Configurable = 'Configurable',
  ConfigurableWithCharge = 'ConfigurableWithCharge',
  Error = 'Error',
  NotConfigurable = 'NotConfigurable'
}

export type ServiceContract = {
  area: ServiceContractArea;
  id: Scalars['ID'];
  included_operating_hours?: Maybe<Scalars['Float']>;
  information?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  number?: Maybe<Scalars['String']>;
  type: ServiceContractType;
  valid_from: Scalars['DateTime'];
  valid_until: Scalars['DateTime'];
};

/** Service contract area */
export enum ServiceContractArea {
  German = 'German',
  Global = 'Global'
}

/** Service contract configurator access state */
export enum ServiceContractConfiguratorAccessState {
  ContractPortfolioNotConfigured = 'ContractPortfolioNotConfigured',
  ContractPortfolioNotFound = 'ContractPortfolioNotFound',
  InvalidPartnerType = 'InvalidPartnerType',
  OtherFailure = 'OtherFailure',
  RolesNotConfigured = 'RolesNotConfigured',
  Success = 'Success',
  WarrantyStartInvalid = 'WarrantyStartInvalid'
}

export type ServiceContractOverview = {
  service_contract_count: Scalars['Int'];
};

/** Service contract types */
export enum ServiceContractType {
  Basic = 'Basic',
  ClassicCoverage = 'ClassicCoverage',
  Comfort = 'Comfort',
  ComfortCoverage = 'ComfortCoverage',
  Complete = 'Complete',
  ConnectedBasic = 'ConnectedBasic',
  ConnectedData = 'ConnectedData',
  ConnectedPlus = 'ConnectedPlus',
  ConnectedPremium = 'ConnectedPremium',
  Perform = 'Perform',
  PerformConnectedPlus = 'PerformConnectedPlus',
  PerformConnectedPremium = 'PerformConnectedPremium',
  Prime = 'Prime',
  PrimeConnectedPremium = 'PrimeConnectedPremium',
  PrimeCoverage = 'PrimeCoverage',
  PrimeMaintenance = 'PrimeMaintenance',
  PrimeMaintenanceConnectedPremium = 'PrimeMaintenanceConnectedPremium',
  PrimePlus = 'PrimePlus',
  Pro = 'Pro',
  ProConnectedPremium = 'ProConnectedPremium',
  Pure = 'Pure',
  PureConnectedPlus = 'PureConnectedPlus',
  PureConnectedPremium = 'PureConnectedPremium',
  Unknown = 'Unknown'
}

export type ServicePartner = {
  active_sim_count_assigned: Scalars['Int'];
  assigned_customer_count: Scalars['Int'];
  assigned_customers_with_bpm_users_count: Scalars['Int'];
  /** Concatenated name and name2 */
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_in_bpm: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  telematic_overview_assigned?: Maybe<TelematicOverview>;
  /**
   *    Telematic counts of all equipments assigned to this partner
   *
   * Auth: Requires 'ms.equipmentcore' scope
   */
  telematic_overview_total?: Maybe<TelematicOverview>;
  type: PartnerType;
};

/** Order by for service partners */
export enum ServicePartnerOrderBy {
  ActiveSimCount = 'ActiveSimCount',
  AssignedCustomerCount = 'AssignedCustomerCount',
  AssignedCustomersWithBpmUsersCount = 'AssignedCustomersWithBpmUsersCount',
  EquipmentCount = 'EquipmentCount',
  Name = 'Name',
  TelematicCount = 'TelematicCount'
}

export type ServicePartnerPageResult = {
  page_number: Scalars['Int'];
  records_per_page: Scalars['Int'];
  results: Array<ServicePartner>;
  total_records: Scalars['Int'];
};

export type ServicePartnerSearchCriteria = {
  /** Filter for service-partners or general-agents that have customers with users in BPM */
  has_customers_with_bpm_users?: InputMaybe<Scalars['Boolean']>;
  /** Filter for partners with assinged telematic equipment */
  has_telematics?: InputMaybe<Scalars['Boolean']>;
  /** Filter for partners in BPM */
  is_service_partner_in_bpm?: InputMaybe<Scalars['Boolean']>;
  numbers?: InputMaybe<Array<Scalars['String']>>;
  /** Filter for partners with assinged equipment having the specified sim status */
  sim_status?: InputMaybe<Array<SimStatus>>;
};

export type ServiceRecord = {
  date?: Maybe<Scalars['String']>;
  details_new?: Maybe<Details>;
  enabled_actions: Array<ServiceRecordAction>;
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  operating_hours?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['ID']>;
  state?: Maybe<ServiceRecordState>;
  state_text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ServiceRecordType>;
  user_company?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** Action of the record in Service History. */
export enum ServiceRecordAction {
  Delete = 'Delete'
}

/** State of the record in Service History. */
export enum ServiceRecordState {
  Error = 'Error',
  Info = 'Info',
  Neutral = 'Neutral',
  Sucess = 'Sucess',
  Warning = 'Warning'
}

/** Type of the record in Service History. */
export enum ServiceRecordType {
  Claim = 'Claim',
  Handover = 'Handover',
  Inspection = 'Inspection',
  MasterDataChange = 'MasterDataChange',
  OilCheck = 'OilCheck',
  OilInformationChange = 'OilInformationChange',
  PalchartFileUpload = 'PalchartFileUpload',
  PaltronicFileUpload = 'PaltronicFileUpload',
  PreDeliveryInspection = 'PreDeliveryInspection',
  ProductRegistration = 'ProductRegistration',
  SsoActivationCodeGenerated = 'SSOActivationCodeGenerated',
  ServiceSchedule = 'ServiceSchedule',
  SmartRecord = 'SmartRecord',
  UpdateConfiguration = 'UpdateConfiguration'
}

/** Status of the estimated service */
export enum ServiceStatus {
  DueSoon = 'DueSoon',
  Operational = 'Operational',
  OverDue = 'OverDue'
}

export type ServiceStatusOverview = {
  due_soon_count: Scalars['Int'];
  over_due_count: Scalars['Int'];
};

export type SetCarrierManufacturerAndTypeInput = {
  carrier_manufacturer: CarrierManufacturer;
  carrier_number: Scalars['String'];
  carrier_type?: InputMaybe<Scalars['String']>;
};

export type SetCarrierManufacturerAndTypeResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetConfigurationValueInput = {
  configuration_update_date: Scalars['String'];
  configurations: Array<EditConfigurationParameters>;
  operating_hours: Scalars['Int'];
};

export type SetConfigurationValueResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetDeleteActiveInspectionInput = {
  equipment_number: Scalars['String'];
  inspection_id: Scalars['Float'];
};

export type SetDeleteActiveInspectionResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetEditOilInformationDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetEditOilInformationInput = {
  equipment_id: Scalars['String'];
  oil_information: OilInformationUpdate;
};

export type SetEndCustomerDataInput = {
  company_number: Scalars['String'];
  end_customer_address: CustomerUpdate;
};

export type SetEndCustomerDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetEquipmentLegalInspectionDateInput = {
  equipment_number: Scalars['String'];
  legal_inspection_date: Scalars['String'];
};

export type SetEquipmentLegalInspectionDateResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetGeneratePalchartResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetGenerateSsoResult = {
  activation_code: Scalars['String'];
  code: Scalars['Int'];
  file_name: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  order_number?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetLicensePlateDataInput = {
  carrier_number: Scalars['String'];
  license_plate: Scalars['String'];
};

export type SetLicensePlateDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetPartnerDocumentInput = {
  document_meta_data: DocumentMetaData;
  file_name: Scalars['String'];
};

export type SetPartnerDocumentResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetRemoveEndCustomerDataInput = {
  equipment_number: Scalars['String'];
};

export type SetRemoveEndCustomerDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetScrapEquipmentDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetScrapEquipmentInput = {
  comment: Scalars['String'];
  equipment_number: Scalars['String'];
  file_name: Scalars['String'];
  operating_hours?: InputMaybe<Scalars['Int']>;
  scrapped_date: Scalars['String'];
};

export type SetServicePartnerInput = {
  equipment_number: Scalars['String'];
  partner_number: Scalars['String'];
};

export type SetServicePartnerResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetTimeToServiceDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetTimeToServiceDateInput = {
  equipment_number: Scalars['String'];
  time_to_service_date: Scalars['String'];
};

export type SetVinDataInput = {
  carrier_number: Scalars['String'];
  vin: Scalars['String'];
};

export type SetVinDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetWarrantyDataResult = {
  code: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SetWarrantyDateInput = {
  comment: Scalars['String'];
  equipment_number: Scalars['String'];
  file_name: Scalars['String'];
  warranty_start_date: Scalars['String'];
};

/** Status of the SIM card */
export enum SimStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  NoSim = 'NoSim'
}

/** Smart handover access state (extended SmartInspectionAccessState) */
export enum SmartHandoverAccessState {
  AlreadyInitialized = 'AlreadyInitialized',
  ProductTypeNotSupported = 'ProductTypeNotSupported',
  RolesNotConfigured = 'RolesNotConfigured',
  Success = 'Success'
}

/** Smart inspection access state */
export enum SmartInspectionAccessState {
  ProductTypeNotSupported = 'ProductTypeNotSupported',
  RolesNotConfigured = 'RolesNotConfigured',
  Success = 'Success'
}

/** Smart record access state (extended SmartRecordAccessState) */
export enum SmartRecordAccessState {
  ProductTypeNotSupported = 'ProductTypeNotSupported',
  RolesNotConfigured = 'RolesNotConfigured',
  Success = 'Success'
}

/** Direction for sorting */
export enum SortDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type TelematicActivationHistory = {
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  target_configuration_type?: Maybe<Scalars['String']>;
};

export type TelematicInfo = {
  can_transmit_machine_data?: Maybe<Scalars['Boolean']>;
  is_gnss_active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ActivationStatus>;
};

export type TelematicOverview = {
  equipments_count: Scalars['Int'];
  non_telematic_count: Scalars['Int'];
  retrofittable_count: Scalars['Int'];
  telematic_count: Scalars['Int'];
};

export type TextFile = {
  /** Base64 encoded file content */
  content: Scalars['String'];
};

export type GetEquipmentIdByEquipmentNumberQueryVariables = Exact<{
  equipmentNumber: Scalars['String'];
}>;


export type GetEquipmentIdByEquipmentNumberQuery = { equipment: { id: string } };

export type GetOperatingHoursQueryVariables = Exact<{
  equipmentNumber: Scalars['String'];
}>;


export type GetOperatingHoursQuery = { equipment: { operating_hours?: number } };

export type GetCustomerDataQueryVariables = Exact<{
  equipmentNumber: Scalars['String'];
}>;


export type GetCustomerDataQuery = { equipment: { is_product_registered: boolean, partners: Array<{ type: PartnerType, is_in_bpm?: boolean }> } };

export type GetApplicationAccessForEquipmentQueryVariables = Exact<{
  equipmentNumber: Scalars['String'];
}>;


export type GetApplicationAccessForEquipmentQuery = { equipment: { serial_number?: string, number: string, name?: string, material_number?: string, application_access: { claim_management_access_state?: ClaimManagementAccessState, handover_fee_access_state?: CrossBoarderSalesAccessState, has_oil_check_access: boolean, has_taillift_shop_access: boolean, pre_delivery_inspection_access_state: PreDeliveryInspectionAccessState, service_contract_configurator_access_state: ServiceContractConfiguratorAccessState, service_fee_access_state?: CrossBoarderSalesAccessState, smart_handover_access_state: SmartHandoverAccessState, smart_inspection_access_state: SmartInspectionAccessState, smart_record_access_state: SmartRecordAccessState, has_service_tickets_access: boolean, has_invite_to_paldesk_access: boolean, has_palchart_access: boolean, has_sso_access: boolean, product_registration_access_info: { access_state: ProductRegistrationAccessState } } } };

export type GetHandoverAndServiceFeeDataQueryVariables = Exact<{
  equipmentNumber: Scalars['String'];
}>;


export type GetHandoverAndServiceFeeDataQuery = { equipment: { serial_number?: string, number: string, name?: string, material_number?: string, handover_and_service_fees?: { handover_fee?: string, service_fee?: string, service_fee_currency?: string } } };

export type GetScrappedEquipmentDataQueryVariables = Exact<{
  equipmentNumber: Scalars['String'];
}>;


export type GetScrappedEquipmentDataQuery = { equipment: { is_scrapped: boolean } };

export type GetSidebarCountDataQueryVariables = Exact<{
  equipmentNumber: Scalars['String'];
}>;


export type GetSidebarCountDataQuery = { equipment: { recall_action_count: number, active_service_contracts_count: number, connected_active_alert_count: number, carrier: { equipments: Array<{ palfinger_documents_count: number, partner_documents_count: number }> } } };

export type ValidateSsoQueryVariables = Exact<{
  fileName: Scalars['String'];
}>;


export type ValidateSsoQuery = { validate_sso: { equipment_number?: string, error_message?: string, option: string, status: SsoValidationStatus } };

export type SetLicensePlateDataMutationVariables = Exact<{
  data: SetLicensePlateDataInput;
}>;


export type SetLicensePlateDataMutation = { setLicensePlateData: { success: boolean, message?: string, code: number } };

export type SetCarrierManufacturerAndTypeMutationVariables = Exact<{
  data: SetCarrierManufacturerAndTypeInput;
}>;


export type SetCarrierManufacturerAndTypeMutation = { setCarrierManufacturerAndType: { success: boolean, message?: string, code: number } };

export type SetCarrierVinMutationVariables = Exact<{
  data: SetVinDataInput;
}>;


export type SetCarrierVinMutation = { setCarrierVin: { success: boolean, message?: string, code: number } };

export type SetGenerateSsoMutationVariables = Exact<{
  data: GenerateSsoDataInput;
}>;


export type SetGenerateSsoMutation = { setGenerateSSO: { activation_code: string, file_name: string, order_number?: string } };

export type SetGeneratePalchartMutationVariables = Exact<{
  data: GeneratePalchartDataInput;
}>;


export type SetGeneratePalchartMutation = { setGeneratePalchart: { code: number, message?: string, success: boolean } };

export type SetScrapEquipmentMutationVariables = Exact<{
  data: SetScrapEquipmentInput;
}>;


export type SetScrapEquipmentMutation = { setScrapEquipment: { success: boolean, message?: string, code: number } };

export type GetFeatureFlagQueryVariables = Exact<{
  featureFlagName: Scalars['String'];
}>;


export type GetFeatureFlagQuery = { feature_flag: { is_enabled: boolean } };

export const GetEquipmentIdByEquipmentNumberDocument = gql`
    query getEquipmentIdByEquipmentNumber($equipmentNumber: String!) {
  equipment(equipment_number: $equipmentNumber) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEquipmentIdByEquipmentNumberGQL extends Apollo.Query<GetEquipmentIdByEquipmentNumberQuery, GetEquipmentIdByEquipmentNumberQueryVariables> {
    override document = GetEquipmentIdByEquipmentNumberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetOperatingHoursDocument = gql`
    query getOperatingHours($equipmentNumber: String!) {
  equipment(equipment_number: $equipmentNumber) {
    operating_hours
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOperatingHoursGQL extends Apollo.Query<GetOperatingHoursQuery, GetOperatingHoursQueryVariables> {
    override document = GetOperatingHoursDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCustomerDataDocument = gql`
    query getCustomerData($equipmentNumber: String!) {
  equipment(equipment_number: $equipmentNumber) {
    partners {
      type
      is_in_bpm
    }
    is_product_registered
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCustomerDataGQL extends Apollo.Query<GetCustomerDataQuery, GetCustomerDataQueryVariables> {
    override document = GetCustomerDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetApplicationAccessForEquipmentDocument = gql`
    query getApplicationAccessForEquipment($equipmentNumber: String!) {
  equipment(equipment_number: $equipmentNumber) {
    serial_number
    number
    name
    material_number
    application_access {
      claim_management_access_state
      handover_fee_access_state
      has_oil_check_access
      has_taillift_shop_access
      pre_delivery_inspection_access_state
      service_contract_configurator_access_state
      service_fee_access_state
      smart_handover_access_state
      smart_inspection_access_state
      smart_record_access_state
      has_service_tickets_access
      has_invite_to_paldesk_access
      has_palchart_access
      has_sso_access
      product_registration_access_info {
        access_state
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetApplicationAccessForEquipmentGQL extends Apollo.Query<GetApplicationAccessForEquipmentQuery, GetApplicationAccessForEquipmentQueryVariables> {
    override document = GetApplicationAccessForEquipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHandoverAndServiceFeeDataDocument = gql`
    query getHandoverAndServiceFeeData($equipmentNumber: String!) {
  equipment(equipment_number: $equipmentNumber) {
    serial_number
    number
    name
    material_number
    handover_and_service_fees {
      handover_fee
      service_fee
      service_fee_currency
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHandoverAndServiceFeeDataGQL extends Apollo.Query<GetHandoverAndServiceFeeDataQuery, GetHandoverAndServiceFeeDataQueryVariables> {
    override document = GetHandoverAndServiceFeeDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetScrappedEquipmentDataDocument = gql`
    query getScrappedEquipmentData($equipmentNumber: String!) {
  equipment(equipment_number: $equipmentNumber) {
    is_scrapped
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetScrappedEquipmentDataGQL extends Apollo.Query<GetScrappedEquipmentDataQuery, GetScrappedEquipmentDataQueryVariables> {
    override document = GetScrappedEquipmentDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSidebarCountDataDocument = gql`
    query getSidebarCountData($equipmentNumber: String!) {
  equipment(equipment_number: $equipmentNumber) {
    recall_action_count
    active_service_contracts_count
    connected_active_alert_count
    carrier {
      equipments {
        palfinger_documents_count
        partner_documents_count
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSidebarCountDataGQL extends Apollo.Query<GetSidebarCountDataQuery, GetSidebarCountDataQueryVariables> {
    override document = GetSidebarCountDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateSsoDocument = gql`
    query validateSSO($fileName: String!) {
  validate_sso(file_name: $fileName) {
    equipment_number
    error_message
    option
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateSsoGQL extends Apollo.Query<ValidateSsoQuery, ValidateSsoQueryVariables> {
    override document = ValidateSsoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetLicensePlateDataDocument = gql`
    mutation setLicensePlateData($data: SetLicensePlateDataInput!) {
  setLicensePlateData(data: $data) {
    success
    message
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetLicensePlateDataGQL extends Apollo.Mutation<SetLicensePlateDataMutation, SetLicensePlateDataMutationVariables> {
    override document = SetLicensePlateDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetCarrierManufacturerAndTypeDocument = gql`
    mutation setCarrierManufacturerAndType($data: SetCarrierManufacturerAndTypeInput!) {
  setCarrierManufacturerAndType(data: $data) {
    success
    message
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCarrierManufacturerAndTypeGQL extends Apollo.Mutation<SetCarrierManufacturerAndTypeMutation, SetCarrierManufacturerAndTypeMutationVariables> {
    override document = SetCarrierManufacturerAndTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetCarrierVinDocument = gql`
    mutation setCarrierVin($data: SetVinDataInput!) {
  setCarrierVin(data: $data) {
    success
    message
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCarrierVinGQL extends Apollo.Mutation<SetCarrierVinMutation, SetCarrierVinMutationVariables> {
    override document = SetCarrierVinDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetGenerateSsoDocument = gql`
    mutation setGenerateSSO($data: GenerateSSODataInput!) {
  setGenerateSSO(data: $data) {
    activation_code
    file_name
    order_number
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetGenerateSsoGQL extends Apollo.Mutation<SetGenerateSsoMutation, SetGenerateSsoMutationVariables> {
    override document = SetGenerateSsoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetGeneratePalchartDocument = gql`
    mutation setGeneratePalchart($data: GeneratePalchartDataInput!) {
  setGeneratePalchart(data: $data) {
    code
    message
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetGeneratePalchartGQL extends Apollo.Mutation<SetGeneratePalchartMutation, SetGeneratePalchartMutationVariables> {
    override document = SetGeneratePalchartDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetScrapEquipmentDocument = gql`
    mutation setScrapEquipment($data: SetScrapEquipmentInput!) {
  setScrapEquipment(data: $data) {
    success
    message
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetScrapEquipmentGQL extends Apollo.Mutation<SetScrapEquipmentMutation, SetScrapEquipmentMutationVariables> {
    override document = SetScrapEquipmentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFeatureFlagDocument = gql`
    query getFeatureFlag($featureFlagName: String!) {
  feature_flag(name: $featureFlagName) {
    is_enabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFeatureFlagGQL extends Apollo.Query<GetFeatureFlagQuery, GetFeatureFlagQueryVariables> {
    override document = GetFeatureFlagDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }