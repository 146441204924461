import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, map } from 'rxjs';

export const UPLOAD_FILE_SERVICE_BASE_PATH = new InjectionToken<string>(
  'UPLOAD_FILE_SERVICE_BASE_PATH',
);

@Injectable({
  providedIn: 'root',
})
export class UploadFileService {
  private basePath: string;

  constructor(
    private _httpClient: HttpClient,
    @Inject(UPLOAD_FILE_SERVICE_BASE_PATH) private apiBasePath: string,
  ) {
    this.basePath = this.apiBasePath;
  }

  public uploadFile(file: File): Observable<string> {
    const body = new FormData();
    body.append('file', file);
    return this._httpClient
      .post<{ filename: string }>(`${this.basePath}/file-upload`, body)
      .pipe(map((response) => response.filename));
  }
}
