import { Injectable } from '@angular/core';
import { UserService } from '@features/auth';
import { GetFeatureFlagGQL } from '@paldesk/workshop-solutions/equipment-shared/data-access-shared';
import { filterTruthy } from '@shared-lib/rxjs';
import { map, Observable, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(
    private _getFeatureFlagGQL: GetFeatureFlagGQL,
    private _userService: UserService,
  ) {}

  isEnabled(name: string): Observable<boolean> {
    return this._userService.currentUser.pipe(
      filterTruthy(),
      take(1),
      switchMap(() => {
        const featureFlag = this._getFeatureFlagGQL.fetch({
          featureFlagName: name,
        });
        return featureFlag.pipe(
          map((result) => result.data.feature_flag.is_enabled),
        );
      }),
    );
  }
}
