import { InjectionToken } from '@angular/core';

export const TAILLIFT_SHOP_BASE = new InjectionToken<string>(
  'tailLiftShopBasePath',
);
export const PRODUCT_REGISTRATION = new InjectionToken<string>(
  'productRegistration',
);
export const CLAIM_MANAGEMENT = new InjectionToken<string>('claimManagement');
export const SMART_PROCESSES = new InjectionToken<string>('smartProcesses');
export const SERVICE_CONTRACT_CONFIGURATOR = new InjectionToken<string>(
  'serviceContractConfigurator',
);
export const OIL_CHECK = new InjectionToken<string>('oilCheck');
export const SERVICE_TICKET = new InjectionToken<string>('serviceTicket');
export const BPM = new InjectionToken<string>('inviteToPaldesk');
