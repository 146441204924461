import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { APPS } from '@config';
import { Menu } from '@design-system/feature/app-wrapper-v2';
import { SatisfactionFeedbackActions } from '@design-system/feature/feedback';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { FeatureFlagService } from '@paldesk/workshop-solutions/equipment-shared/utils-shared';
import { filterTruthy } from '@shared-lib/rxjs';
import { take } from 'rxjs';
import {
  SATISFACTION_FEEDBACK_FEATURE_KEY,
  SP_FEATURE_FLAG_PREFIX,
} from './shared/constants/constants';

@Component({
  selector: 'service-inspection-root',
  templateUrl: './app.component.html',
  standalone: false,
})
export class AppComponent implements OnInit {
  isImportManager = false;

  menu: Menu = {
    title: 'Smart Processes',
    titleIcon: 'pd-oh-tippingOperations',
    navigation: [
      {
        title: 'Overview',
        icon: 'assignment',
        routerLink: '/overview',
      },
      {
        title: 'Content management',
        icon: 'pd-oh-leverNotNeutral',
        routerLink: '/content-management',
        children: [
          {
            title: 'Inspection Lists',
            routerLink: '/content-management/inspection-lists',
          },
          {
            title: 'Categories',
            routerLink: '/content-management/categories',
          },
          {
            title: 'Groups',
            routerLink: '/content-management/groups',
          },
        ],
      },
    ],
  };

  satisfactionFeedbackFeatureKey = SATISFACTION_FEEDBACK_FEATURE_KEY;

  constructor(
    private _userService: UserService,
    private _featureFlagService: FeatureFlagService,
    private _store: Store,
  ) {
    this._featureFlagService
      .isEnabled(SP_FEATURE_FLAG_PREFIX + 'SHOW_FEEDBACK_DIALOG_ENABLED')
      .pipe(takeUntilDestroyed())
      .subscribe((isEnabled) => {
        if (isEnabled) {
          this._store.dispatch(
            SatisfactionFeedbackActions.GetUserSettings({
              key: this.satisfactionFeedbackFeatureKey,
            }),
          );
        }
      });
  }

  ngOnInit(): void {
    this._userService.isAuthorized$
      .pipe(filterTruthy(), take(1))
      .subscribe(
        () =>
          (this.isImportManager = this._userService.hasRole(
            APPS.SMART_PROCESSES.ROLES.IMPORT_MANAGER,
          )),
      );
  }
}
